export default [
  {
    VEHICLE_TYPE_NAME: 'LKW'
  },
  {
    VEHICLE_TYPE_NAME: 'LKW Plane'
  },
  {
    VEHICLE_TYPE_NAME: 'LKW Kofferaufbau'
  },
  {
    VEHICLE_TYPE_NAME: 'LKW Kühl-Thermo'
  },
  {
    VEHICLE_TYPE_NAME: 'LKW Tank-Silo'
  },
  {
    VEHICLE_TYPE_NAME: 'SZM'
  },
  {
    VEHICLE_TYPE_NAME: 'SZM+Auflieger Plane'
  },
  {
    VEHICLE_TYPE_NAME: 'SZM+Auflieger Kofferaufbau'
  },
  {
    VEHICLE_TYPE_NAME: 'SZM+Kühl-Thermoauflieger'
  },
  {
    VEHICLE_TYPE_NAME: 'SZM+Tank-Siloauflieger'
  },
  {
    VEHICLE_TYPE_NAME: 'Sattelauflieger'
  },
  {
    VEHICLE_TYPE_NAME: 'Lieferwagen'
  },
  {
    VEHICLE_TYPE_NAME: 'PKW'
  },
  {
    VEHICLE_TYPE_NAME: 'Tieflader'
  },
  {
    VEHICLE_TYPE_NAME: 'Tankfahrzeug'
  },
  {
    VEHICLE_TYPE_NAME: 'Silozug'
  },
  {
    VEHICLE_TYPE_NAME: 'Kühl-Thermofahrzeug'
  },
  {
    VEHICLE_TYPE_NAME: 'Sonderfahrzeug'
  },
  {
    VEHICLE_TYPE_NAME: 'Milchwagen'
  },
  {
    VEHICLE_TYPE_NAME: 'Betonmischer'
  },
  {
    VEHICLE_TYPE_NAME: 'Kipper'
  },
  {
    VEHICLE_TYPE_NAME: 'Pritsche'
  },
  {
    VEHICLE_TYPE_NAME: 'Anhänger'
  },
  {
    VEHICLE_TYPE_NAME: 'Anhänger Plane'
  },
  {
    VEHICLE_TYPE_NAME: 'Anhänger Kofferaufbau'
  },
  {
    VEHICLE_TYPE_NAME: 'Auflieger'
  },
  {
    VEHICLE_TYPE_NAME: 'Wechselbrücke'
  },
  {
    VEHICLE_TYPE_NAME: 'Wechselbehälter'
  },
  {
    VEHICLE_TYPE_NAME: 'Container'
  }
];
